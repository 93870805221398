<template>
  <v-main>
      <div
        :class="[$vuetify.breakpoint.mdAndUp && 'fill-height']"
        class="d-block d-md-flex"
      >
        <page-app-bar />

        <router-view />

        <page-footer />
      </div>
  </v-main>
</template>

<script>
  // Utilities
  import { get } from 'vuex-pathify'

  export default {
    name: 'PageView',

    components: {
      PageAppBar: () => import('./AppBar'),
      PageFooter: () => import('./Footer'),
    },

    data: () => ({
      srcs: {
        '/pages/lock/': 'lock.jpg',
        '/pages/login/': 'login.jpg',
        '/pages/pricing/': 'pricing.jpg',
        '/pages/register/': 'register.jpg',
      },
    }),

    computed: {
      path: get('route/path'),
      src () {
        return this.srcs[this.path] || 'clint-mckoy.jpg'
      },
    },
  }
</script>
